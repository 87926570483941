<template>
  <b-nav-item-dropdown right toggle-class="d-flex align-items-center dropdown-user-link" class="dropdown-user">
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <!-- TODO: FIX USER -->
        <p v-if="user" class="user-name font-weight-bolder mb-0">{{ user.username }}</p>
        <span class="user-status text-danger">{{ roleName }}</span>
      </div>
      <b-avatar size="40" :src="require(`@/assets/images/avatars/13-small.png`)" variant="light-primary" badge
        class="badge-minimal" badge-variant="success">
        <feather-icon icon="UserIcon" size="22" />
      </b-avatar>
    </template>

    <b-dropdown-item link-class="d-flex align-items-center" @click.stop="togglePasswordModal">
      <feather-icon size="16" icon="KeyIcon" class="mr-50" />
      <span>Password</span>
    </b-dropdown-item>

    <b-dropdown-divider />

    <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
      <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
      <span>Logout</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar } from "bootstrap-vue";
import { removeAbility } from "@/libs/acl/ability";
import { avatarText } from "@core/utils/filter";
import { mapState } from "vuex";

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem("userData")),
      avatarText,
    };
  },
  computed: {
    ...mapState(`auth`, ["user"]),
    roleName() {
      if (this.user)
        return this.user.roleName.replace(/([a-z])([A-Z])/g, "$1 $2").replace(/([A-Z])([A-Z][a-z])/g, "$1 $2");
      else return null;
    },
  },
  methods: {
    async logout() {
      this.$store.dispatch(`auth/LOGOUT`);
      removeAbility();
      this.$router.push({ name: `auth-login` });
    },
    togglePasswordModal() {
      this.$store.dispatch("app/TOGGLE_PASSWORD");
    },
  },
};
</script>
