export default [
  {
    title: "Dashboard",
    icon: "MonitorIcon",
    route: "dashboard",
    action: "manage",
    resource: "dashboard",
  },
  {
    title: "Agent",
    icon: "TabletIcon",
    route: "agent-list",
    resource: "agent",
  },
  {
    title: "user",
    icon: "UsersIcon",
    route: "user-list",
    action: "read",
    resource: "member",
  },
];
